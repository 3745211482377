import React, { useEffect } from 'react';
import axios from 'axios';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';
import { Shimmer } from 'react-shimmer';
import { useUrlContext } from '../Context/UrlContext';

function Gallery() {
  const { galleryData, lastSegment } = useUrlContext();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    if (galleryData) {
      // Initialize GLightbox once images are loaded
      GLightbox({
        selector: '.glightbox',
      });
    }
  }, [galleryData]);

  if (!galleryData) {
    return (
      <div className="container mt-5">
        {/* <div className="row g-0 isotope-container" data-aos-delay={200}>
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <div className="portfolio-content h-100">
                <Shimmer width="100%" height={300} />
              </div>
            </div>
          ))}
        </div> */}
      </div>
    );
  }

  const { images } = galleryData;

  return (
    <section id="portfolio" className="portfolio section">
      <div className="container section-title">
        <h2>Portfolio</h2>
      </div>
      <div className="container-fluid">
        <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
          <div className="row g-0 isotope-container" data-aos-delay={200}>
            {images
              .filter(image => image.status === "active")
              .map((image, index) => (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                  <a href={image.imageId} data-gallery="portfolio-gallery" className="glightbox">
                    <div className="portfolio-content h-100">
                      <img src={`${baseUrl}/uploads/${lastSegment}/${image.imageId}`}  className="img-fluid" alt={image.alt || 'Gallery Image'} />
                      <div className="portfolio-info">
                        {/* Additional info if needed */}
                      </div>
                    </div>
                  </a>
                </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
