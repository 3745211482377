import React from 'react';
import { Shimmer } from 'react-shimmer';
import { useUrlContext } from '../Context/UrlContext';
import { useLocation } from "react-router-dom";

function About() {
  const { aboutData, lastSegment } = useUrlContext();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  // const location = useLocation();
  // const lastSegment = location.pathname.split("/").filter(Boolean).pop();





    // Check if aboutData is defined and has the properties
    const logoid = aboutData?.logoid || null;
    const aboutContent = aboutData?.aboutContent || '';



    if (!aboutData) {
      return (
        <div className="container mt-5">
        
        </div>
      );
    }

  return (
    <div id="about">
      <div className="container-xxl">
        <div className="container">
          <h2>About Us</h2>
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="d-flex justify-content-center align-items-center h-100">
            <img className="img-fluid"  src={`${baseUrl}/uploads/${lastSegment}/${logoid}`}  style={{ maxWidth: '50%', height: 'auto', borderRadius:'5px' }} />
          </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <p dangerouslySetInnerHTML={{ __html: aboutContent }} style={{ textAlign: 'justify' }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
