import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Menu = () => {
  const { guid } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;


  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(
          `https://newqrdapi.qrdcard.com/api/restraunt/menu?guid=${guid}`
        );
        setImages(response.data.menu);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch menu images");
        setLoading(false);
      }
    };

    fetchMenu();
  }, [guid]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="grid-container">
      {images.map((url, index) => (
        <div key={index} className="grid-item">
          <img src={`${baseUrl}/uploads/${guid}/${url}`} alt={`Image ${index + 1}`} className="image" />
        </div>
      ))}
    </div>
  );
};

export default Menu;
