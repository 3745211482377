import React, { useState } from 'react';
import { useUrlContext } from '../Context/UrlContext'; // Import the custom hook to access URL context

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { aboutData , lastSegment } = useUrlContext(); // Get the aboutData from the context
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;


  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Check if aboutData is defined and has the properties
  const logoSrc = aboutData?.logoid || null;
  const logoText = aboutData?.logoText || '';


  
  if (!aboutData) {
    return (
      <div className="container mt-5">
      
      </div>
    );
  }


  return (
    <header id="header" className={`header d-flex align-items-center sticky-top ${isMobileMenuOpen ? 'mobile-nav-active' : ''}`}>
      <div className="container-fluid position-relative d-flex align-items-center justify-content-between">
        <a href="#" className="logo d-flex align-items-center me-auto me-xl-0">
          {logoSrc ? (
            <img className="img-fluid text-center" src={`${baseUrl}/uploads/${lastSegment}/${logoSrc}`} alt="Logo" width="100%" />
          ) : (
            <div className="logo-text" style={{ fontSize: '24px', fontWeight: 'bold', color: '#000' }}>
              {logoText}
            </div>
          )}
        </a>
        <nav id="navmenu" className="navmenu">
          <ul className="nav-items">
            <li><a href="#hero" onClick={closeMobileMenu}><strong>Home</strong></a></li>
            <li><a href="#about" onClick={closeMobileMenu}><strong>About</strong></a></li>
            <li><a href="#services" onClick={closeMobileMenu}><strong>Services</strong></a></li>
            <li><a href="#portfolio" onClick={closeMobileMenu}><strong>Portfolio</strong></a></li>
            <li><a href="#contact" onClick={closeMobileMenu}><strong>Contact</strong></a></li>
          </ul>
          <i
            className={`mobile-nav-toggle d-xl-none bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'}`}
            onClick={handleMobileMenuToggle}
          />
        </nav>
      </div>
    </header>
  );
}

export default Header;
