import React, { useContext } from 'react';
import { Shimmer } from 'react-shimmer';
import { useUrlContext } from '../Context/UrlContext';
import { useNavigate } from 'react-router-dom';

function Services() {
  const { servicesData, lastSegment } = useUrlContext();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;


  if (!servicesData) {
    return (
      <div className="container mt-5">

      </div>
    );
  }

  const { services } = servicesData;

  return (
    <div id="services">
      <section className='section1'>
        <h2>Services</h2>
        <div className="container1">
          {services
            .filter(service => service.status === "active")
            .map((service, index) => (
              <div 
                className="card" 
                key={index} 
                style={{ cursor: 'pointer' }}
                // onClick={() => navigate(`/service/${index}`)}  // Navigate using index as identifier
              >
                <div className="card-inner" style={{ '--clr': '#fff' }}>
                  <div className="box">
                    <div className="imgBox">
                      <img src={`${baseUrl}/uploads/${lastSegment}/${service.imageId}`} alt={service.serviceTitle} />
                    </div>
                    <div className="icon">
                      <a href="#" className="iconBox">
                        <span className="material-symbols-outlined">
                          arrow_outward
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <h3><strong>{service.serviceTitle}</strong></h3>
                  <p dangerouslySetInnerHTML={{ __html: service.shortDesc }} style={{ textAlign: 'justify' }} />
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
}

export default Services;
