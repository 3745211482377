import React, { useContext } from 'react';
import { Shimmer } from 'react-shimmer';
import { useUrlContext } from '../Context/UrlContext'; // Assuming the file path to context is correct

function Hero() {
  const { slidesData , lastSegment } = useUrlContext();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;


  return (
    <div id="hero" className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-inner">
          {slidesData ? (
            slidesData.images.filter(slide => slide.status === "active").map((slide, index) => (
              <div key={slide._id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img className="w-100" src={`${baseUrl}/uploads/${lastSegment}/${slide.imageId}`} alt={`Slide ${index + 1}`} />
              </div>
            ))
          ) : (
            // Render shimmer placeholders while loading
            <>
             
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Hero;
